/**
 * 本地化配置
 * 配置文字的本地化，包括语言、货币、图片的path等
 *
 */

export default {
  locationKey: "PK",
  currencyCode: "PKR",
  currencyName: "Pakistan Rupee",
  gameName: "GameLuka",
  appVersion: "1.1.1",
  forceUpdateToVersion: "1.1.1",
  companyName: "GAMELUKA",
  licence: "No.0000357",
  availableCountryList: ["PK"],
  icon: require("@/assets/icon_luka.png").default,
  appIcon: require("@/assets/app_icon_luka.png").default,
  appIcon64: require("@/assets/app_icon_64x64_luka.png").default,
  appIconRound: require("@/assets/icon_round_luka.png").default,
  appIconRound64: require("@/assets/icon_round_64x64_luka.png").default,
  titleImg: require("@/assets/images/header/fz_char_logo_luka.png").default,
  titleImgWhite: require("@/assets/images/header/fz_char_logo_white_luka.png").default,
  indexBannerHarvestImg: require("@/assets/images/home/index_banner_harvest_in.png").default,
  indexBannerDepositImg: "index_banner_deposit_pk",
  indexBannerDeposit2Img: "index_banner_deposit2_in",
  indexBannerDrawImg: "index_banner_draw",
  indexBannerinviteImg: require("@/assets/images/home/index_banner_invite_in.png").default,
  indexBannerLuckyWheelImg: "index_banner_lucky_wheel_pk",
  indexBannerInstallImg: "index_banner_install_pk",
  indexBannerLeaderImg: "index_banner_leader_in",
  downloadTipTextImg: "top_zi_pk",
  referFriendsPopup: require("@/assets/images/home/ing_2_in.png").default,
  registerDownloadAd: "reg_download_pk",
  downloadFloatImg: require("@/assets/images/download/float-download-pk.png").default,
  luckyWheelShareImg: "/assets/images/lucky_wheel/banner_x2_in.png",
};
