const payments = [
  {
    text: "3",
    enable: true,
    merchantId: 18,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 1200000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "4",
    enable: true,
    merchantId: 19,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 2500000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "8",
    enable: true,
    merchantId: 20,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "UPI 9",
    enable: true,
    merchantId: 21,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "11",
    enable: true,
    merchantId: 22,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "9",
    enable: true,
    merchantId: 23,
    type: "UPI",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "11",
    enable: true,
    merchantId: 24,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "12",
    enable: true,
    merchantId: 25,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "decenpay",
    enable: true,
    merchantId: 26,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "mmpay",
    enable: true,
    merchantId: 27,
    type: "BANKCHOOSE",
    img: "../assets/images/home/easypais.png",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "decenpkpay",
    enable: true,
    merchantId: 29,
    type: "BANKNAME",
    img: "../assets/images/home/jazz.png",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 30000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
];

const codes = [
  {
    codeId : "JAZZCASH",
    codeText : "JAZZCASH",
  },
  {
    codeId : "EASYPAISA",
    codeText : "EASYPAISA",
  },
  {
    codeId : "001",
    codeText : "ALBARAKA ISLAMIC BANK",
  },
  {
    codeId : "002",
    codeText : "ALLIED BANK LIMITED",
  },
  {
    codeId : "003",
    codeText : "APNA MICRO FINANCE BANK",
  },
  {
    codeId : "004",
    codeText : "ASKARI BANK LIMITED",
  },
  {
    codeId : "005",
    codeText : "BANK AL HABIB LIMITED",
  },
  {
    codeId : "006",
    codeText : "BANK ALFALAH LIMITED",
  },
  {
    codeId : "007",
    codeText : "BANK ISLAMI PAKISTAN LIMITED",
  },
  {
    codeId : "008",
    codeText : "BANK OF KHYBER",
  },
  {
    codeId : "009",
    codeText : "CITI BANK NA",
  },
  {
    codeId : "010",
    codeText : "DUBAI ISLAMIC BANK PAKISTAN LIMITED",
  },
  {
    codeId : "011",
    codeText : "FAYSAL BANK LIMITED",
  },
  {
    codeId : "012",
    codeText : "FINCA MICRO FINANCE BANK",
  },
  {
    codeId : "013",
    codeText : "FIRST WOMEN BANK LIMITED",
  },
  {
    codeId : "014",
    codeText : "HABIB BANK LIMITED",
  },
  {
    codeId : "015",
    codeText : "HABIB METROPOLITAN BANK LIMITED",
  },
  {
    codeId : "016",
    codeText : "JS BANK LIMITED",
  },
  {
    codeId : "017",
    codeText : "MCB BANK LIMITED",
  },
  {
    codeId : "018",
    codeText : "MCB ISLAMIC",
  },
  {
    codeId : "019",
    codeText : "MEEZAN BANK",
  },
  {
    codeId : "020",
    codeText : "NATIONAL BANK OF PAKISTAN",
  },
  {
    codeId : "021",
    codeText : "NRSP MICRO FINANCE BANK",
  },
  {
    codeId : "022",
    codeText : "NIB BANK LIMITED",
  },
  {
    codeId : "023",
    codeText : "SAMBA BANK LIMITED",
  },
  {
    codeId : "024",
    codeText : "SILK BANK LIMITED",
  },
  {
    codeId : "025",
    codeText : "SINDH BANK LIMITED",
  },
  {
    codeId : "026",
    codeText : "SONERI BANK LIMITED",
  },
  {
    codeId : "027",
    codeText : "STANDARD CHARTERED BANK LTD",
  },
  {
    codeId : "028",
    codeText : "SUMMIT BANK LIMITED",
  },
  {
    codeId : "029",
    codeText : "TELENOR MICRO FINANCE BANK",
  },
  {
    codeId : "030",
    codeText : "THE BANK OF PUNJAB",
  },
  {
    codeId : "031",
    codeText : "U MICRO FINANCE BANK",
  },
  {
    codeId : "032",
    codeText : "UNITED BANK LIMITED",
  },
];

const names = [
  {
    nameText : "Jazz cash",
  },
  {
    nameText : "Easypaisa",
  },
];

export default {
  payments: payments.filter((payment) => payment.enable),
  withdraw_type: "UPI", // BANKCARD, MOBILE_MONEY
  codes: codes,
  names: names,
};
